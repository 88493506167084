import React, { lazy } from "react";
import { useConfig } from "../../../../api/config";
import { rolesRestrictionsContentGenerator } from "../../../../functionLib/rolesRestrictions";
import svgLib from "../../../../functionLib/svglib";
import { CustomerType } from "../../../../types/customer";
import { CustomerPermission } from "../../../../types/customerRole";

const config = useConfig();

const DashbordComponent = lazy(
  () => import("../DashbordComponent/DashbordComponent")
);
const ConversationsComponent = lazy(
  () => import("../ConversationsComponent/ConversationsComponent")
);
const InsightsComponent = lazy(
  () => import("../InsightsComponent/InsightsComponent")
);
const AssistansComponent = lazy(() => import("../Assistants"));
const TeamMenuComponent = lazy(
  () => import("../TeamMenuComponent/TeamMenuComponent")
);
const Dashboardmenu = lazy(() => import("../Dashboardmenu/Dashboardmenu"));
const SettingsComponent = lazy(
  () => import("../SettingsComponent/SettingsComponent")
);
const GeneralComponent = lazy(
  () => import("../GeneralComponent/GeneralComponent")
);
const AllUsersComponent = lazy(
  () => import("../AllUsersComponent/AllUsersComponent")
);
const ChannelsComponent = lazy(
  () => import("../ChannelsComponent/ChannelsComponent")
);
const IntegrationsComponent = lazy(
  () => import("../IntegrationsComponent/IntegrationsComponent")
);
const GrowtoolsComponent = lazy(
  () => import("../GrowtoolsComponent/GrowtoolsComponent")
);
const WebsiteMessagesComponent = lazy(
  () => import("../WebsiteMessagesComponent/WebsiteMessagesComponent")
);
const SkillsComponent = lazy(
  () => import("../SkillsComponent/SkillsComponent")
);
const KnowledgeComponent = lazy(
  () => import("../KnowledgeComponent/KnowledgeComponent")
);
const NLPComponent = lazy(() => import("../NLPComponent/NLPComponent"));

const BillingProvider = lazy(
  () => import("../BillingComponent/BillingComponent")
);
const Broadcasts2Component = lazy(
  () => import("../Broadcasts2Component/Broadcasts2Component")
);
const TeamComponent = lazy(() => import("../TeamComponent/TeamComponent"));
const AutomationComponent = lazy(
  () => import("../AutomationComponent/AutomationComponent")
);
const AgentsComponent = lazy(
  () => import("../AgentsComponent/AgentsComponent")
);
const IntentsComponent = lazy(
  () => import("../IntentsComponent/IntentsComponent")
);

const Data = [
  {
    id: "_dashButton1",
    name: "Dashboard",
    linkTo: "dashboard",
    component: (
      <Dashboardmenu
        subRoutes={[
          {
            linkTo: "analytics",
            name: "analytics",
            component: <DashbordComponent />,
          },
        ]}
        link="dashboard"
      />
    ),
    img: svgLib.dashboard(),
    subRoutes: [{ name: "Charts", linkTo: "analytics" }],
  },

  {
    id: "_dashButton1",
    name: "Conversations",
    linkTo: "conversations",
    component: (
      <ConversationsComponent
        subRoutes={[
          { linkTo: "users", name: "users", component: <AllUsersComponent /> },
          // {
          //   linkTo: "broadcasts",
          //   name: "broadcasts",
          //   component: <Broadcasts2Component />,
          // },
        ]}
        link="conversations"
      />
    ),
    img: svgLib.conversations(),
    subRoutes: [
      { name: "Chats", linkTo: "users" },
      // { name: "Broadcasts", linkTo: "broadcasts" },
    ],
  },
  {
    id: "_dashButton1",
    name: "Automation",
    linkTo: "automation",
    component: (
      <AutomationComponent
        subRoutes={[
          {
            linkTo: "intents",
            name: "intents",
            component: <IntentsComponent />,
          },
          // {
          //   linkTo: "insights",
          //   name: "insights",
          //   component: <InsightsComponent />,
          // },
          { linkTo: "skills", name: "skills", component: <SkillsComponent /> },
          {
            linkTo: "knowledge",
            name: "knowledge",
            component: <KnowledgeComponent />,
          },
          {
            linkTo: "assistants",
            name: "assistants",
            component: <AssistansComponent />,
          },
          // {
          //   linkTo: "web-messages",
          //   name: "webMessages",
          //   component: <WebsiteMessagesComponent />,
          // },
          // { linkTo: "nlp", name: "Dialogflow", component: <NLPComponent /> },
        ]}
        link="automation"
      />
    ),
    img: svgLib.dialogflow(),
    subRoutes: [
      { name: "Intents", linkTo: "intents" },
      // { name: "Insights", linkTo: "insights" },
      { name: "Skills", linkTo: "skills" },
      { name: "Knowledge", linkTo: "knowledge" },
      { name: "Assistants", linkTo: "assistants" },
      // { name: 'Website messages', linkTo: 'web-messages' },
      // { name: "Dialogflow", linkTo: "nlp" },
    ],
  },
  {
    id: "_dashButton1",
    name: "Team",
    linkTo: "team",
    component: (
      <TeamMenuComponent
        subRoutes={[
          { linkTo: "roles", name: "roles", component: <TeamComponent /> },
          { linkTo: "agents", name: "agents", component: <AgentsComponent /> },
        ]}
        link="team"
      />
    ),
    img: svgLib.livechat(),
    subRoutes: [
      { name: "Roles", linkTo: "roles" },
      { name: "Agents", linkTo: "agents" },
    ],
  },
  {
    id: "_dashButton1",
    name: "Settings",
    linkTo: "settings",
    component: (
      <SettingsComponent
        subRoutes={[
          {
            linkTo: "general",
            name: "general",
            component: <GeneralComponent />,
          },
          {
            linkTo: "channels",
            name: "channels",
            component: <ChannelsComponent />,
          },
          {
            linkTo: "integrations",
            name: "integrations",
            component: <IntegrationsComponent />,
          },
          {
            linkTo: "growtools",
            name: "growtools",
            component: <GrowtoolsComponent />,
          },
          {
            linkTo: "billing",
            name: "billing",
            component: <BillingProvider />,
          },
        ]}
        link="settings"
      />
    ),
    img: svgLib.settings(),
    subRoutes: [
      { name: "General", linkTo: "general" },
      { name: "Channels", linkTo: "channels" },
      { name: "Integrations", linkTo: "integrations" },
      { name: "Grow tools", linkTo: "growtools" },
      ...(config?.BILLING_SERVICE_URL && config.STRIPE_PUBLIC_KEY
        ? [{ name: "Billing", linkTo: "billing" }]
        : []),
    ],
  },
].map((item, i) => ({ ...item, id: item.name + i }));

const DataNavigation = (
  customer: CustomerType | null,
  customerRoles: CustomerPermission[]
) =>
  Data.map((item) => ({
    ...item,
    subRoutes: item.subRoutes.filter((sr) =>
      rolesRestrictionsContentGenerator(sr, [], customerRoles, customer)
    ),
  })).filter((item) => item.subRoutes.length);

export default DataNavigation;
