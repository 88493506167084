export type ApiConfig = {
  GATEWAY_URL: string;
  ARCHITECT_URL: string;
  ROLES_SERVICE_URL: string;
  BILLING_SERVICE_URL: string;
  USER_SERVICE_URL: string;
  DEFAULT_API_ERROR: string;
  LIVECHAT_SERVICE_URL: string;
  STRIPE_PUBLIC_KEY: string;
  REACT_APP_ANALYTICS_TOKEN: string;
  DISABLE_BACKGROUND_QUEUE_CHECK: boolean;
  FB_APP_ID: string;
  PRIVACY_POLICY_LINK: string;
  TERMS_SERVICE_LINK: string;
  AI_SERVICE_URL: string;
  BRAND_IMAGE_LINK: string;
  LET_TALK_LINK: string;
};

const loadConfig = (): ApiConfig => ({
  GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL || window._env_?.GATEWAY_URL,
  ARCHITECT_URL:
    process.env.REACT_APP_ARCHITECT_URL || window._env_?.ARCHITECT_URL,
  ROLES_SERVICE_URL:
    process.env.REACT_APP_ROLES_SERVICE_URL || window._env_.ROLES_SERVICE_URL,
  BILLING_SERVICE_URL:
    process.env.REACT_APP_BILLING_SERVICE_URL ||
    window._env_?.BILLING_SERVICE_URL,
  STRIPE_PUBLIC_KEY:
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || window._env_?.STRIPE_PUBLIC_KEY,
  USER_SERVICE_URL:
    process.env.REACT_APP_USER_SERVICE_URL || window._env_?.USER_SERVICE_URL,
  DEFAULT_API_ERROR:
    window._env_?.DEFAULT_API_ERROR ||
    "Something went wrong. Please try again in a moment.",
  LIVECHAT_SERVICE_URL:
    process.env.REACT_APP_LIVECHAT_SERVICE_URL ||
    window._env_?.LIVECHAT_SERVICE_URL,
  AI_SERVICE_URL:
    process.env.REACT_APP_AI_SERVICE_URL || window._env_?.AI_SERVICE_URL,
  REACT_APP_ANALYTICS_TOKEN:
    process.env.REACT_APP_ANALYTICS_TOKEN ||
    window._env_?.REACT_APP_ANALYTICS_TOKEN,
  BRAND_IMAGE_LINK:
    process.env.REACT_APP_BRAND_IMAGE_LINK || window._env_?.BRAND_IMAGE_LINK,
  FB_APP_ID: window._env_?.FB_APP_ID,
  DISABLE_BACKGROUND_QUEUE_CHECK: false,
  PRIVACY_POLICY_LINK:
    process.env.REACT_APP_PRIVACY_POLICY_LINK ||
    window._env_?.PRIVACY_POLICY_LINK,
  TERMS_SERVICE_LINK:
    process.env.REACT_APP_TERMS_SERVICE_LINK ||
    window._env_?.TERMS_SERVICE_LINK,
  LET_TALK_LINK:
    process.env.REACT_APP_LET_TALK_LINK || window._env_?.LET_TALK_LINK,
});

const config = loadConfig();
console.log("[config] config", config);

export const useConfig = (override?: Partial<ApiConfig>): ApiConfig => ({
  ...config,
});
